<template>
 <PageHeading
  :button-title="$t('newUser')"
  :has-meta-data="false"
  :has-action-button="true"
  :has-search="true"
  :has-permission="userPermissions.permissions.includes('contacts.*')"
  :title="$t('usersTitle')"
  @open="openForm = !openForm"
  @update:search="$emit('update:search', $event)"
 />

 <div v-if="data?.data?.length > 0">
  <div class="flex flex-col">
   <div class="-my-0 sm:-mx-6 lg:-mx-0">
    <div class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
     <div v-if="openForm" class="ring-1 ring-black ring-opacity-10 bg-white p-10 rounded-lg">
      <NewContact
       :is-edit="false"
       @close-form="openForm = false"
       @activeBanner="$emit('activeBanner')"
       @successMsg="$emit('successMsg', $event)"
       @success="$emit('success', $event)"
      />
     </div>
     <div v-else>
      <ContactTableComponent
       :thead="thead"
       :tbody="this.filtered()"
       :data="data"
       :has-permission="
        userPermissions.permissions.includes('contacts.*') ||
        userPermissions.permissions.includes('contacts.delete')
       "
       @action="getData($event, this.per_page)"
       @set-rows="getData(null, $event)"
       @action-detail="goToDetail($event)"
       @action-delete="deleteUser($event)"
      />
     </div>
    </div>
   </div>
  </div>
 </div>
 <div v-else>
  <EmptyState
   :title="$t('emptyStateTitle', { title: $t('document') })"
   :subtitle="$t('emptyStateSubtitle', { title: $t('document') })"
  />
 </div>
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import ContactTableComponent from "../components/ContactTableComponent.vue";
import NewContact from "../components/NewContact.vue";
import PageHeading from "../components/PageHeading.vue";
import EmptyState from "../components/EmptyState.vue";

const adminToken = localStorage.getItem("adminToken");
const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

const people = [
 {
  firstName: "Lindsay",
  lastName: "Walton",
  emailAddress: "lindsay.walton@example.com",
  mobilePhone: "",
  landlinePhone: "0110000011",
  active: true,
  created_at: "2023-01-04T14:21:58.000000Z",
  updated_at: "2023-01-04T14:21:58.000000Z",
 },
 {
  firstName: "Courtney",
  lastName: "Henry",
  emailAddress: "courtney.henry@example.com",
  mobilePhone: "0612345678",
  landlinePhone: "",
  active: true,
  created_at: "2023-01-04T14:21:58.000000Z",
  updated_at: "2023-01-04T14:21:58.000000Z",
 },
 {
  firstName: "Tom",
  lastName: "Cook",
  emailAddress: "tom.cook@example.com",
  mobilePhone: "0698765432",
  landlinePhone: "0110000010",
  active: true,
  created_at: "2023-01-04T14:21:58.000000Z",
  updated_at: "2023-03-04T09:11:34.000000Z",
 },
 {
  firstName: "Whitney",
  lastName: "Francis",
  emailAddress: "whitney.francis@example.com",
  mobilePhone: "0612983487",
  landlinePhone: "",
  active: false,
  created_at: "2023-01-04T14:21:58.000000Z",
  updated_at: "2023-05-04T18:12:19.000000Z",
 },

 // More people...
];
export default {
 props: ["search"],
 components: {
  AskConfirmationDialog,
  ContactTableComponent,
  NewContact,
  PageHeading,
  EmptyState,
 },
 data() {
  return {
   adminToken,
   people,
   account,
   activeBanner: false,
   currentSort: "id",
   currentSortDir: "asc",
   data: [],
   openForm: false,
   paginationData: [],
   thead: [],
   tbody: [],
   per_page: 5,
   userPermissions,
  };
 },
 created() {
  //firstthis.iconHovers = this.files.map(() => false);
 },
 methods: {
  async getData(url, per_page) {
   this.$emit("setLoading", true);
   let page = "";
   if (url) {
    // Use the URL constructor to parse the URL and get the value of the 'page' query parameter
    const parsedUrl = new URL(url);
    page = parsedUrl.searchParams.get("page");
   } else {
    page = "1";
   }
   if (per_page) this.per_page = per_page;

   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/users?customerAccount=${
      this.account
     }&page=${page}&per_page=${this.per_page}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.data = res.data;
    const keyArr = Object.keys(res.data.data[0]);
    const excludedValues = [
     "contactable_type",
     "contactable_id",
     "civility",
     "source",
     "created_at",
    ];
    this.thead = keyArr
     .filter((name) => !excludedValues.includes(name))
     .map((name) => ({ name, sort: "name", isSort: false }));

    this.tbody = this.data.data.map((obj) => {
     const filteredObj = Object.fromEntries(
      Object.entries(obj).filter(([key]) => !excludedValues.includes(key))
     );
     return filteredObj;
    });
    this.$emit("setLoading", false);
   } catch (error) {
    this.$emit("setLoading", false);
    console.error(error.response);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  goToDetail(id) {
   let routeData = undefined;
   routeData = this.$router.resolve({
    path: `/contact/${id}`,
   });
   this.$router.push(routeData.path);
  },
  async deleteUser(data) {
   let id = data.id;
   let name = data.firstName + " " + data.lastName;
   let title = this.$t("deleteContact", { name: name });
   let confirmationMessage = this.$t("deleteContactMessage");
   let confirmButton = this.$t("delete");
   let goBack = this.$t("cancel");
   let successMsg = this.$t("elementDeletedSuccessfully", { element: this.$t("userMessage") });
   const options = {
    method: "DELETE",
    url: `${this.$cookie.getCookie("API")}/api/v1/users/${id}?customerAccount=${this.account}`,
    headers: {
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .request(options)
     .then((response) => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", successMsg);
      this.getData();
     })
     .catch((err) => {
      console.error(err);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sorted() {
   return this.tbody.sort((a, b) => {
    // Sort active: false (0) to the bottom
    if (a.active === 0 && b.active === 1) {
     return 1;
    } else if (a.active === 1 && b.active === 0) {
     return -1;
    }

    // Sort by id from biggest to lowest
    if (a.id > b.id) {
     return -1;
    } else if (a.id < b.id) {
     return 1;
    }
    return 0;
   });
  },
  filtered() {
   return this.sorted().filter((res) => {
    if (this.search) {
     const searchLower = this.search.toLowerCase();
     const fullName = res.firstName + res.lastName;
     const firstNameMatch = res.firstName && res.firstName.toLowerCase().includes(searchLower);
     const lastNameMatch = res.lastName.toLowerCase().includes(searchLower);
     const fullNameMatch = fullName.toLowerCase().includes(searchLower.replace(/\s/g, ""));
     const emailAddressMatch = res.emailAddress.toLowerCase().includes(searchLower);
     const mobilePhoneMatch =
      res.mobilePhone && res.mobilePhone.toLowerCase().includes(searchLower);
     const landlinePhoneMatch =
      res.landlinePhone && res.landlinePhone.toLowerCase().includes(searchLower);

     const createdAtMatch = this.$d(res.created_at, "shortText")
      .toLowerCase()
      .includes(searchLower.toLowerCase());
     const updatedAtMatch = this.$d(res.updated_at, "longText")
      .toLowerCase()
      .includes(searchLower.toLowerCase());

     return (
      firstNameMatch ||
      lastNameMatch ||
      fullNameMatch ||
      emailAddressMatch ||
      mobilePhoneMatch ||
      landlinePhoneMatch ||
      createdAtMatch ||
      updatedAtMatch
     );
    }
    return res;
   });
  },
 },

 mounted() {
  this.getData();
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>
